import { render, staticRenderFns } from "./OpenDay.vue?vue&type=template&id=719e28e6&"
import script from "./OpenDay.vue?vue&type=script&lang=ts&"
export * from "./OpenDay.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports